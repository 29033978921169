<template>
  <Teleport to="#teleports">
    <KPrompt
      action-button-appearance="danger"
      :action-button-disabled="loading"
      :action-button-text="t('actions.delete_application')"
      :confirmation-text="name"
      data-testid="apps-delete-confirmation"
      :modal-attributes="{ maxWidth: '640px' }"
      :title="t('apps.delete.confirmation_title')"
      :visible="isVisible"
      @cancel="close"
      @proceed="proceedRemoval"
    >
      <template #default>
        <PortalKAlert
          v-if="errorText"
          appearance="danger"
          class="delete-error"
          data-testid="apps-delete-confirmation-error"
          :message="errorText"
          show-icon
        />

        <p>{{ t('apps.delete.confirmation_text') }}</p>
      </template>
    </KPrompt>
  </Teleport>
</template>

<script setup lang="ts">
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  applicationId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'delete'): void
}>()

const { deleteApplication, deleteApplicationError } = useApplicationCRUD()
const { t } = useI18n()

watch(() => props.isVisible, (visible) => {
  if (!visible) {
    deleteApplicationError.value = null
  }
})

const close = (): void => {
  deleteApplicationError.value = null
  emit('close')
}

const loading = useState<boolean>(() => false)

const proceedRemoval = async (): Promise<void> => {
  loading.value = true

  await deleteApplication(props.applicationId)

  loading.value = false

  if (!deleteApplicationError.value) {
    emit('delete')
  }
}

const errorText = computed((): string => deleteApplicationError.value ? parseApiError(deleteApplicationError.value as any) : '')
</script>

<style scoped lang="scss">
.delete {
  &-error {
    margin-bottom: var(--kui-space-70, $kui-space-70);
  }
}
</style>
